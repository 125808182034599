<template>
  <div>
    <el-row>
      <el-col :span="6">
        <h4>Filter by Type:</h4>
        <el-select
          v-model="selectedTypes"
          multiple
          placeholder="Select types"
        >
          <el-option
            v-for="jobType in types"
            :key="jobType.value"
            :label="jobType.label"
            :value="jobType.value"
          />
        </el-select>
        <el-button
          style="margin-left: 2em;"
          size="small"
          @click="clearSelectedTypes"
        >
          Clear
        </el-button>
      </el-col>
      <el-col :span="6">
        <h4>Filter by Status:</h4>
        <el-checkbox-group v-model="selectedStatuses">
          <el-checkbox
            v-for="status in statuses"
            :key="status.value"
            :label="status.value"
          >
            {{ status.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-col>
      <el-col :span="6">
        <h4>Look back (hours):</h4>
        <el-input-number
          ref="lookbackHours"
          v-model="lookBackHours"
          :min="1"
          :step="1"
          :disabled="lookBackDays > 0"
          placeholder="Optional"
        />
      </el-col>
      <el-col :span="6">
        <h4>Look back (days):</h4>
        <el-input-number
          ref="lookbackDays"
          v-model="lookBackDays"
          :min="0"
          :max="7"
          :step="1"
          :step-strictly="true"
          placeholder="Optional"
        />
      </el-col>
      <el-col :span="6">
        <el-checkbox v-model="orgFilterEnabled">
          <h3>Filter by Org</h3>
        </el-checkbox>

        <org-picker
          v-if="orgFilterEnabled"
          :organization.sync="org"
        />
      </el-col>
      <el-col style="padding-top: 2em;">
        <el-button
          type="primary"
          @click="fetchData"
        >
          Fetch Data
        </el-button>        <el-tooltip
          effect="dark"
          placement="right"
        >
          <template #content>
            <div>
              <h3>How to use this component:</h3>
              <ul>
                <li>Select job types and/or statuses to filter by</li>
                <li>Optionally, set look back hours or days to filter jobs within a specific time frame</li>
                <li>Click 'Fetch Data' to see the results</li>
                <li>You can also search for jobs by label using the search box</li>
                <li>Click on a job ID to open it in a new tab</li>
                <li>Change the status of a job and click 'Resolve' to update it</li>
              </ul>
            </div>
          </template>
          <i
            class="el-icon-question"
          />
        </el-tooltip>
      </el-col>
      <el-col :span="6">
        <h4>Search Jobs:</h4>

        <el-input
          v-model="searchQuery"
          placeholder="Search jobs by label"
          @keyup.enter.native="fetchData"
        />
      </el-col>
    </el-row>

    <el-table
      v-if="paginatedJobs.length > 0"
      v-loading="loading"
      :data="paginatedJobs"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="id"
      >
        <template slot-scope="scope">
          <a
            :href="`/jobs/${scope.row.id}`"
            target="_blank"
          >
            {{ scope.row.id }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(value, key) in jobs[0]"
        v-if="!excludedColumns.includes(key)"
        :key="key"
        :prop="key"
        :label="key"
      />
      <el-table-column
        prop="status"
        label="Status"
      >
        <template slot-scope="scope">
          <div :class="getStatusClass(scope.row.status)">
            {{ scope.row.status }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="hasPermission('can_resolve_jobs')"
        prop="selectedStatus"
        label="Selected Status"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.selectedStatus"
            placeholder="Select"
            @change="changeStatus(scope.$index)"
          >
            <el-option
              v-for="status in statuses"
              :key="status.value"
              :label="status.label"
              :value="status.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        v-if="hasPermission('can_resolve_jobs')"
        label="Resolve"
      >
        <template slot-scope="scope">
          <el-button @click="resolve(scope.$index)">
            Resolve
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :total="jobs.length"
      :page-size="pageSize"
      @current-change="handleCurrentChange"
    />
    <div
      v-if="jobs.length <= 0"
      class="no-data-message"
    >
      <div class="no-data-icon">
        <!-- Add a custom icon or illustration here -->
        <i class="el-icon-s-data" />
      </div>
      <h1 class="no-data-text">
        No data available
      </h1>
    </div>
  </div>
</template>

<script>
import {
  job as jobApi,
  system as systemApi,
} from '@/adonis-api';
import { MessageBox } from 'element-ui';
import OrgPicker from '@/components/global/OrgPicker.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    OrgPicker,
  },
  data() {
    return {
      jobs: [],
      loading: false,
      selectedTypes: [],
      currentPage: 1,
      searchQuery: '',
      lookBackDays: null,
      orgFilterEnabled: false,

      pageSize: 10,
      org: null,
      types: [],
      selectedStatuses: [],
      statuses: [
        { value: 'COMPLETE', label: 'Complete' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'PROCESSING', label: 'Processing' },
        { value: 'POST-PROCESSING', label: 'Post-Processing' },
        { value: 'FAILED', label: 'Failed' },
      ],
      lookBackHours: null,
      excludedColumns: ['meta', 'deleted_at', 'parent_type', 'lock', 'selectedStatus', 'status', 'id'],
    };
  },
  computed: {
    paginatedJobs() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.jobs.slice(startIndex, endIndex);
    },
    ...mapGetters('user', ['hasPermission']),

  },
  async mounted() {
    this.types = await systemApi.getJobTypes();
    this.$nextTick(() => {
      const inputEl = this.$refs.lookbackHours.$el.querySelector('input');
      inputEl.addEventListener('keypress', this.onKeyPress);
    });
  },
  methods: {
    handleCurrentChange(page) {
      // Update the current page when the user clicks the pagination links
      this.currentPage = page;
    },
    changeStatus(index) {
      const selectedStatus = this.jobs[index].selectedStatus;
      this.jobs[index].status = selectedStatus;
    },
    onKeyPress(event) {
      if (event.key === '.' || event.key === ',') {
        event.preventDefault();
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        let response;
        const lookBackHours = this.lookBackDays > 0 ? this.lookBackDays * 24 : this.lookBackHours;
        const orgId = this.orgFilterEnabled ? this.org.id : null;
        if (this.searchQuery) {
          response = await jobApi.searchJobs({
            types: this.selectedTypes,
            statuses: this.selectedStatuses,
            updated_since_hours: lookBackHours,
            search_query: this.searchQuery,
            org_id: orgId,
          });
        } else {
          response = await jobApi.fetchJobs({
            types: this.selectedTypes,
            statuses: this.selectedStatuses,
            updated_since_hours: lookBackHours,
            org_id: orgId,
          });
        }
        this.jobs = response.data;
      } catch (error) {
        this.$message({
          message: error,
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },

    clearSelectedTypes() {
      this.selectedTypes = [];
    },

    async resolve(index) {
      if (!this.jobs[index].selectedStatus) {
        this.$message({
          message: 'You must select a status to resolve to.',
          type: 'error',
        });
        return;
      }
      const confirmResult = await MessageBox.confirm('Are you sure you want to resolve this job?', 'Confirmation', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning',
      });
      if  (confirmResult === 'confirm')  {
        const jobId = this.jobs[index].id;
        try {
          await jobApi.resolveJob({ job_id: jobId, status: this.jobs[index].selectedStatus });
          this.$message({
            message: 'Job resolved successfully',
            type: 'success',
          });
        } catch (error) {
          this.$message({
            message: 'Failed to resolve job',
            type: 'error',
          });
        }
      }
    },
    getStatusClass(status) {
      if (status === 'COMPLETE') {
        return { 'status-complete': true, 'status-text': true };
      } else if (status === 'FAILED') {
        return { 'status-failed': true, 'status-text': true };
      }
      return { 'status-other': true, 'status-text': true };
    },

  },
};
</script>

 <style scoped>
.status-complete {
  background-color: #D0F0C0;
  color: green;
}
.status-failed {
  background-color: #F0C0C0;
  color: red;
}
.status-other {
  background-color: #C0D0F0;
  color: blue;
}
.status-text {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-data-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.no-data-icon {
  font-size: 5rem;
  color: #ccc;
  margin-bottom: 1rem;
}

.no-data-text {
  font-size: 1.5rem;
  color: #888;
  text-align: center;
} </style>
