<template>
  <div>
    <el-dialog
      title="Generate Full Report"
      :visible.sync="reportDialog"
      width="30%"
      :before-close="handleClose"
    >
      <span>Are you sure you want to generate a full report?</span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="reportDialog = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="generateReport()"
        >Confirm</el-button>
      </span>
    </el-dialog>
    <div class="flex-container">
      <div>
        <el-breadcrumb
          v-if="dashboardData"
          separator-class="el-icon-arrow-right"
        >
          <el-breadcrumb-item
            v-for="(org, idx) in breadcrumb"
            v-if="selectedOrg != orgIdByName[org]"
            :key="idx"
          >
            <a
              @click="breadcrumbNavigate(org)"
            ><span
              style="margin-left:15px;margin-right: 10px;"
              class="breadcrumb-link"
            >{{ org }}</span></a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div
        v-if="hasPermission('administer_organizations')"
      >
        <el-select
          v-if="dashboardData"
          v-model="selectedOrg"
          placeholder="Select Organization"
          style="margin-left: 15px"
          @change="updateCharts()"
        >
          <el-option
            v-for="org in dashboardData.organizations"
            :key="org.name"
            :label="org.name"
            :value="org.id"
          >
            {{ org.name }}
          </el-option>
        </el-select>
        <el-switch
          v-model="incDesc"
          style="padding-left: 20px;"
          active-text="Sub Orgs"
        />
      </div>

      <div class="push">
        <date-picker
          :date-range.sync="dateRange"
          :to="new Date()"
        />
      </div>
      <div>
        <el-button
          style="margin-right: 15px;"
          @click="updateCharts()"
        >
          Go
        </el-button>
      </div>
    </div>
    <el-row
      class="gutters"
    >
      <el-card class="box-card">
        <div
          slot="header"
          style="display: flex;"
        >
          <div style="flex-grow: 1;font-size: 20px;margin-top: .5em;margin-left:.5em">
            <span
              v-if="overview && breadcrumb.length > 0"
            >{{ breadcrumb[breadcrumb.length-1].name }} Overview</span>
          </div>
          <div>
            <el-button
              v-if="overview"
              @click="reportDialog = true"
            >
              Generate Full Report
            </el-button>
          </div>
        </div>

        <el-col
          v-if="!dashboardData"
          class="spinner"
          :span="3"
        >
          <positional-spinner
            right="0"
            top="0"
            static
          />
        </el-col>
        <div v-if="dashboardData">
          <el-row
            type="flex"
            class="row-bg"
            justify="space-between"
          >
            <el-col
              v-for="(result, label, index) in overview"
              :key="index"
              :span="4"
            >
              <el-card class="box-card item-cards capitalize">
                <div style="text-align:center;">
                  {{ formatCardLabel(label, 0) }}<br><br>
                  <span style="font-size:2rem">{{ result.toLocaleString() }}</span>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-row>
    <el-row class="gutters">
      <el-tabs
        v-model="selectedTab2"
        type="border-card"
      >
        <el-tab-pane
          v-for="(section, name, sectionIndex) in dashboardSections"
          :key="section.id + 1"
          :label="section.cardLabel"
          :span="12"
          lazy
        >
          <el-card class="box-card">
            <el-container>
              <el-container>
                <div
                  v-if="labels.length > 1 && dashboardData && !chartsDisabled"
                  class="chart-os"
                >
                  <el-card
                    v-show="chartDataExists(name)"
                    class="box-card"
                  >
                    <div>
                      <stacked-line-chart
                        v-if="sectionIndex == selectedTab2"
                        style="height:575px;padding-bottom:50px;"
                        :chartdata="chartData(name, sectionIndex === Object.keys(dashboardSections).length - 1)"
                        :options="getOptions(name)"
                      />
                    </div>
                  </el-card>
                  <div
                    v-if="!chartDataExists(name)"
                    style="height:675px;"
                  >
                    <img
                      v-if="logoUrl"
                      :src="logoUrl"
                      class="no-data-img"
                    >
                  </div>
                </div>
                <div
                  v-show="chartsDisabled"
                  class="spinner"
                >
                  <positional-spinner
                    right="0"
                    static
                    top="0"
                    :size="100"
                  />
                </div>
              </el-container>
            </el-container>
            <el-row
              v-if="detailSections[
                section.id
              ] && chartDataExists(name)"
              style="margin-top:15px"
            >
              <el-col
                v-for="(detailSection, label, index) in detailSections[
                  section.id
                ]"
                :key="index"
                :span="4"
              >
                <div>
                  <el-card
                    v-if="detailSections[section.id][label] > 0"
                    class="box-card item-cards capitalize"
                  >
                    <div style="text-align:center;">
                      {{ formatCardLabel(label) }}<br><br>
                      <span style="font-size:1.5rem">{{
                        detailSections[section.id][label].toLocaleString()
                      }}</span>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StackedLineChart from '@/components/dashboard/StackedLineChart';
import DatePicker from '../../global/DatePicker.vue';
import { dashboard } from '@/adonis-api';
import PositionalSpinner from '../../global/PositionalSpinner.vue';
import moment from 'moment';

const rgbColorList = [
  '#AAD3DF',
  '#DEB5A9',
  '#A9DED0',
  '#A9B7DE',
  '#85BF86',
  '#FBE59D',
  '#9DB3FA',
  '#FAB69D',
  '#E1FA9D',
];
const dataSetMap = {
  publications: {
    name: 'publication_result',
    target_values: ['publish_count'],
    render: true,
    cardLabel: 'Publications',
    label: 'Publication Devices',
    id: 'pb',
    totalLabel: '_Total Device Count',

  },
  creation: {
    name: 'audience_result',
    target_values: ['audience_device_count'],
    render: true,
    cardLabel: 'Audiences',
    id: 'au',
    label: 'Audience Devices',
    totalLabel: '_Total Device Count',

  },
  geo_creation: {
    name: 'geoframe_result',
    target_values: ['geoframe_count'],
    render: true,
    cardLabel: 'Geoframes',
    label: 'Geoframe Devices',
    id: 'gf',
    totalLabel: '_Total Device Count',

  },
  attribution_usage: {
    name: 'attribution_result',
    target_values: ['destination_count', 'source_count'],
    render: true,
    cardLabel: 'Attribution Reports',
    label: 'Attribution Devices',
    id: 'at',
    totalLabel: '_Total Device Count',

  },
  downloads: {
    name: 'download_result',
    target_values: ['download_total'],
    render: true,
    cardLabel: 'Downloads',
    label: 'Total downloads',
    id: 'dl',
    totalLabel: '_Total Downloads',
  },
};

export default {
  components: {
    StackedLineChart,
    DatePicker,
    PositionalSpinner,
  },
  data() {
    return {
      overview: null,
      userMap: {},
      orgMap: {},
      dashboardData: null,
      incDesc: true,
      selectedOrg: null,
      selectedOrgBilling: null,
      dashboardCardDetails: {},
      billingRates: null,
      dataIds: ['au', 'dl', 'pb', 'at', 'gf'],
      dataTable: {
        order: 'ascending',
        sortBy: 'created',
      },
      dateRange: [
        moment()
          .subtract(30, 'days')
          .utc(),
        moment().utc(),
      ],
      selectedDesign: 1,
      selectedTab: null,
      chartsDisabled: true,
      detailsDisabled: false,
      labels: [],
      reportDialog: false,
      detailSections: {},
      MAX_DAYS: 95,
      breadcrumb: new Set(),
      selectedTab2: '',
      orgIdByName: {},
    };
  },

  computed: {
    dashboardSections() {
      return dataSetMap;
    },
    ...mapGetters('user', ['fullName', 'isInternal', 'hasPermission']),
    ...mapGetters('app', ['logoUrl']),
    findOptions() {
      return {
        start: moment(this.dateRange[0])
          .utc()
          .format('YYYY-MM-DD'),
        end: moment(this.dateRange[1]).add(1, 'days')
          .utc()
          .format('YYYY-MM-DD'),
      };
    },
  },

  created() {},
  async mounted() {
    await this.createLabels();
    this.getDashBoardData();
  },

  methods: {

    async overviewData() {
      this.overview = {
        'Audiences Created': 0,
        'Audiences Published': 0,
        'Devices Published': 0,
        'Attribution Reports Created': 0,
        'Total Usage Cost': 0,
      };
      try {
        if (this.detailSections.au && this.detailSections.au.au_audience_reports_total) {
          this.overview['Audiences Created'] = this.detailSections.au.au_audience_reports_total;
        }

        if (this.detailSections.pb && this.detailSections.pb.pb_publish_reports_total) {
          this.overview['Audiences Published'] = this.detailSections.pb.pb_publish_reports_total;
        }
        if (this.dashboardData.billingCalculations && this.dashboardData.billingCalculations.total) {
          this.overview['Total Usage Cost'] = (this.dashboardData.billingCalculations.total).toLocaleString('en-US',
            {
              style: 'currency',
              currency: 'USD',
            });
        }

        if (this.detailSections.at && this.detailSections.at.at_attribution_reports_total) {
          this.overview['Attribution Reports Created'] = this.detailSections.at.at_attribution_reports_total;
        }
      } catch (e) {
        this.$reportError(e);
      }
    },
    handleClose() {
      this.reportDialog = false;
    },
    async generateReport() {
      this.reportDialog = false;
      const res = await dashboard.generateReport(this.findOptions.start, this.findOptions.end, this.selectedOrg);
      this.$alert(res.message);
    },
    breadcrumbNavigate(orgName) {
      this.selectedOrg = this.orgIdByName[orgName];
      const tmpBread = new Set();
      const breadcrumbArry = Array.from(this.breadcrumb.values());
      for (const entry in breadcrumbArry) {
        if (this.orgIdByName[breadcrumbArry[entry]] !== this.selectedOrg) {
          tmpBread.add(breadcrumbArry[entry]);
        } else {
          tmpBread.add(breadcrumbArry[entry]);
          break;
        }
      }
      if (tmpBread.size === 0) {
        tmpBread.add(breadcrumbArry[0]);
      }
      this.breadcrumb = tmpBread;
      this.updateCharts();
    },
    orgName(id) {
      let orgName = 'n/a';
      this.dashboardData.organizations.forEach(org => {
        if (org.id === id) {
          orgName = org.name;
        }
      });
      return orgName;
    },
    async computeDetailsSection() {
      this.detailSections = {};

      this.dashboardData.user_result.forEach(user => {
        this.userMap[user.id] = { first_name: user.first_name.substring(0, 1) + '.'.toUpperCase(), last_name: user.last_name };
        this.orgMap[user.organization_id] = { name: this.orgName(user.organization_id) };
        for (const key of Object.keys(user)) {
          const id = key.substring(0, 2);
          if (!this.dataIds.includes(id)) {
            continue;
          }
          if (!(id in this.detailSections)) {
            this.detailSections[id] = {};
          }
          if (!(key in this.detailSections[id])) {
            this.detailSections[id][key] = 0;
          }
          this.detailSections[id][key] += parseInt(user[key]);
        }
      });
    },

    async createLabels() {
      try {
        this.labels = [];
        const day = this.dateRange[0]._isAMomentObject
          ? this.dateRange[0]
          : moment(this.dateRange[0]).utc();
        const end = this.dateRange[1]._isAMomentObject
          ? this.dateRange[1]
          : moment(this.dateRange[1]).utc();
        let start = day;
        for (let i = 0;  i <= Math.abs(end.diff(day, 'days')); i++) {
          this.labels.push(start.format('YYYY-MM-DD'));
          start = start.clone().add(1, 'd');
        }
      } catch (e) {
      }
    },
    formatCardLabel(label, substr = 3) {
      if (label.includes('poi')) {
        label = label.replace('poi', 'quick_select');
      };
      if (label.includes('auto_polygon')) {
        label = label.replace('auto_polygon', 'commercial_address');
      };

      if (label.includes('reverse_appends')) {
        label = label.replace('reverse_appends', 'residential_address');
      };
      return label
        .substring(substr)
        .split('_')
        .join(' ');
    },
    reset() {
      this.selectedOrg = null;
      this.dateRange = [
        moment()
          .subtract(30, 'days')
          .utc(),
        moment().utc(),
      ];
      this.incDesc = true;
      this.updateCharts();
    },
    async updateCharts() {
      if (moment().utc().diff(this.findOptions.start, 'days') > this.MAX_DAYS) {
        return this.$alert(`Earliest start date is ${moment().subtract(this.MAX_DAYS, 'days').utc().format('MM-DD-YYYY')} please select a more recent date.`, 'Maximum Days',
          {
            confirmButtonText: 'OK',
            type: 'error',
            callback: action => {

            },
          });
      }
      this.chartsDisabled = true;
      this.detailsDisabled = true;
      this.labels = [];
      this.dashboardData = null;
      await this.createLabels();
      this.getDashBoardData();
    },
    async getDashBoardData() {
      this.chartsDisabled = true;
      this.detailsDisabled = true;
      this.dashboardData = null;
      const end = this.findOptions.end;
      this.dashboardData = await dashboard.getMetrics(
        this.findOptions.start,
        end,
        this.selectedOrg,
        this.incDesc,
      );
      await this.computeDetailsSection();
      await this.overviewData();
      const breadcrumbOrg = this.dashboardData.organization_results.requesting_org[0];
      this.orgIdByName[breadcrumbOrg.name] = breadcrumbOrg.id;
      if (this.selectedOrg !== this.orgIdByName[breadcrumbOrg.name] || !this.breadcrumb.has(breadcrumbOrg.name)) {
        this.breadcrumb.add(breadcrumbOrg.name);
      }
      this.selectedOrg = breadcrumbOrg.id;
      this.chartsDisabled = false;
    },
    handleClick(tab, event) {},
    design(num) {
      this.selectedDesign = num;
    },
    getOptions(chartName) {
      return {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
          text: '',
        },
        tooltips: {
          enabled: true,
          mode: 'index',
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Date',
              },

              ticks: {
                display: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: dataSetMap[chartName].label,
              },
            },
          ],
        },
      };
    },
    chartDataExists(chartName) {
      if (this.dashboardData[dataSetMap[chartName].name].length > 0) {
        return true;
      }
      return false;
    },
    chartData(chartName, lastChart = false) {
      const perUserDetail = {};
      const data = [];
      let chartAggregate = 0;

      const id = dataSetMap[chartName].id;
      const dataSet = dataSetMap[chartName].name;
      const targetValues = dataSetMap[chartName].target_values;
      const totalLabel = dataSetMap[chartName].totalLabel;

      function createData(username, result) {
        perUserDetail[username] = {
          label: username,
          borderColor: rgbColorList[result.user_id % 8],
          backgroundColor: rgbColorList[result.user_id % 8],
          data: [],
        };
      }

      function alignIndex(username, result, labels) {
        const labelIdx = labels.indexOf(result.created_at.split('T')[0]);
        const idxDiff = perUserDetail[username].data.length - labelIdx - 1;
        for (let i = 1; i < Math.abs(idxDiff); i++) {
          perUserDetail[username].data.push(0);
        }
      }

      function alignIndexTail(labels) {
        Object.keys(perUserDetail).forEach((k, i) => {
          while (perUserDetail[k].data.length <= labels.length - 1) {
            perUserDetail[k].data.push(0);
          }
          data.push(perUserDetail[k]);
        });
      }

      function pushNewData(username, args) {
        const addedAmount = args.reduce((a, b) => {
          return +a + +b;
        }, 0);
        chartAggregate += addedAmount;
        perUserDetail[username].data.push(addedAmount);
      }

      function processResult(result, labels, userMap, orgMap) {
        const orgName = (result.organization_id in orgMap) ? orgMap[result.organization_id].name : result.organization_id;
        const firstName = (result.user_id in userMap) ? userMap[result.user_id].first_name : result.user_id;
        const lastName = (result.user_id in userMap) ? userMap[result.user_id].last_name : '-';
        const username = firstName + ' ' + lastName + '(' + orgName + ')';
        const argArray = [];

        for (const idx in targetValues) {
          argArray.push(result[targetValues[idx]]);
        }

        if (!(username in perUserDetail)) {
          createData(username, result);
        }

        alignIndex(username, result, labels);

        pushNewData(username, argArray);
      }
      if (
        !this.dashboardData[dataSet] ||
        targetValues.length <= 0 ||
        dataSetMap[chartName].render === false
      ) {
        return null;
      }
      this.dashboardData[dataSet].forEach(result => {
        processResult(result, this.labels, this.userMap, this.orgMap);
      });

      alignIndexTail(this.labels);
      if (!(id in this.detailSections)) {
        this.detailSections[id] = {};
      }
      this.detailSections[id][id + totalLabel] = chartAggregate;
      if (lastChart) {
        this.detailsDisabled = false;
      }
      if (id + totalLabel === 'pb_Total Device Count') {
        this.overview['Devices Published'] = chartAggregate;
      }
      return {
        labels: this.labels,
        datasets: data,
      };
    },
  },
};
</script>

<style>
.gutters {
  padding: 0.75rem;
}

.left-gutter {
  padding-left: 0.75em;
}

.chart-os {
  display: block;
  -webkit-box-flex: 1;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  box-sizing: border-box;
}

.item-cards {
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
  height: 130px;
}

.date-picker {
  display: inline-block;
  margin: 0 1em 1em 0;
  position: relative;
  top: 1px;
}

.spinner {
  margin: auto;
}

.capitalize {
  text-transform: capitalize;
}

.no-data-img {
  display: block;
  margin-left: auto;
  padding-top:250px;
  margin-right: auto;
  width: 50%;
}
.flex-container {
  display: flex;
  align-items: center;
}

.push {
  margin-left: auto;
}

.breadcrumb-link {
  font-weight: bold;
  cursor: pointer;
}

.el-tabs--border-card>.el-tabs__header {
  background-color: #EBEEF5;
}
</style>
